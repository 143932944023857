import { Storage } from "aws-amplify";

import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import Status from "components/Status";
import {
  AMAZON_CLIENT_RESULT,
  CHECKBOX_ICON_OPTIONS,
  CLAIM_SUBMIT_STATUS,
  CONFIG,
  ADMIN_USERS,
  CUSTOM_INS,
  INSURANCE_PROVIDER,
  INVOICE_DEFAULT_MESSAGE,
  LOCATION,
  REGION,
  STATUS,
  newRegisterObj,
  MEDICARE_ID,
  BILL_PATIENT_INS,
  ERA_DENAIEL_REASONS,
} from "constant";
import FileSaver from "file-saver";
import { isValidNumber } from "libphonenumber-js";
import moment from "moment";
import momentTZ from "moment-timezone";
import postalCodes from "postal-codes-js";
import { jsonToCSV } from "react-papaparse";
import { formatPhoneNumberIntl, isPossiblePhoneNumber } from "react-phone-number-input";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { customKeyBy } from "util/customLodash";
import { TIMEZONE_ABBREVIATIONS } from "constant";
import { DEFAULT_USRERS_PERMISSIONS } from "constant";
import API from "api";
import { HOSPICE_MODFIRE } from "constant";
import { LABEL_MAP_USER_LOGS } from "constant";

export const downloadDataAsCSV = async (data, fileName) => {
  const content = jsonToCSV(data);
  const fileContent = new Blob([content], { type: "csv" });
  await FileSaver.saveAs(fileContent, `${fileName}.csv`);
};

export const formatPhoneNumber = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }
    const phone = formatPhoneNumberIntl(phone_number);
    return phone;
  } catch (err) {
    console.log("Error", err);
  }
  return phone_number_value;
};

export const getFormatedDate = (date) => {
  const inputDate = moment(date);
  return inputDate.format("MM/DD/YYYY");
};

export const phoneFormatter = (phone) => {
  if (phone) {
    const d = setPhoneNo(phone);
    return d;
  }
  return phone;
};

export const formatPhoneForCalRedie = (phoneNumber) => {
  if (!phoneNumber) return "";

  let phone = formatPhoneNumber(phoneNumber) ?? phoneNumber;

  phone = phone || phoneNumber;

  phone = phone.replace(/\D/g, "").replace(/^1/, "");
  const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    phone = `${match[1]}-${match[2]}-${match[3]}`;
  }

  return phone;
};

export const isValidEmail = (email) => {
  if (
    new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(email)
  ) {
    return true;
  } else return false;
};
export const isValidCallTime = (clt) => {
  if (new RegExp(/^([01]\d|2[0-3])(:)([0-5]\d)?$/g).test(clt)) {
    return true;
  }
  return false;
};

export const SYSTEM_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const removeWhiteSpaces = (str) => {
  if (!str) return "";
  return str.replace(/\s/g, "");
};
export const checkValidity = (name) => {
  if (!name) return "";
  return name.toLowerCase().replace(/[^A-Z0-9]/gi, "");
};

export const RemoveSpaceAndChar = (name) => {
  if (!name) return "";
  return name.replace(/[^A-Z0-9]/gi, "");
};

export const removeSpaceIns = (name) => {
  if (!name) return "";
  return name.toLowerCase().replace(/\s/g, "");
};
export const toTitleCase = (str) => {
  if (!str) return "";
  // if(str.length === 2 || str.length === 3) return str.toUpperCase();
  return str.toLowerCase().replace(/(^\w)|([-\s]\w)/g, (match) => match.toUpperCase());
};
export const CapsFirstLetter = (str) => {
  if (typeof str !== "string" || !str.trim()) {
    return "";
  }

  return str.replace(/(\b\w{1,3}\b|\b\w{4,}\b)/g, (word) => {
    if (word.length <= 3) {
      return word.toUpperCase();
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
};

export const CapitalizeFirstLetterOfWords = (str) => {
  if (typeof str !== "string" || !str.trim()) {
    return "";
  }

  return str.replace(/(\b\w{1,3}\b|\b\w{4,}\b)/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};

export const StatusFormatter = (str) => {
  if (typeof str !== "string" || !str.trim()) {
    return "";
  }

  return str.replace(/(\b\w{1,3}\b|\b\w{4,}\b)/g, (word) => {
    if (/^\d/.test(word) && word.length <= 3) {
      return word;
    } else if (word.length <= 3) {
      return word.toUpperCase();
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
};

export const convertToLower = (val) => {
  const v = getValidSpaces(val);
  if (v) return v.toLowerCase();
  return val;
};
export const convertToUpper = (val) => {
  const v = getValidSpaces(val);
  if (val) return v.toUpperCase();
  return val;
};

export const getPercentage = (obtained, total) => {
  return Math.round((obtained / total) * 100) || 0;
};

export const convertYYYYMMDDToDate = (str, format) => {
  if (!str) return "";
  const year = str.slice(0, 4);
  const month = str.slice(4, 6);
  const day = str.slice(6, 8);

  return moment(`${year}-${month}-${day}`).format(format || "MMM DD,YYYY");
};

export const claimLogsTitle = (msg) => {
  if (msg?.toLowerCase() === "submit for bill") {
    return "Submitted to bill";
  } else if (msg?.toLowerCase() === "re-submit for bill") {
    return "Re-Submitted to bill";
  }
  return msg;
};

export const convertMmYyyyToDate = (mmyyyy) => {
  var month = parseInt(mmyyyy.substring(0, 2)) - 1; // Months are zero-indexed
  var year = parseInt(mmyyyy.substring(2));
  return new Date(year, month, 1);
};

export const getCallTime = (clt) => {
  if (!clt) return "";
  const time = clt.split(":");
  if (time[0].length == 1) time[0] = "0" + time[0];
  const calltime = [...time];
  if (calltime[1] % 15 != 0) {
    calltime[1] = calltime[1] - (calltime[1] % 15);
    if (calltime[1] == 0) calltime[1] = "00";
    return calltime.join(":");
  }
  return time.join(":");
};
export const getValidDep = (name) => {
  if (!name || !name.trim()) return "";
  return toTitleCase(name.replace(/  +/g, " "));
};
export const getValidReg = (reg) => {
  if (!reg) return "";
  return CapsFirstLetter(reg.replace(/  +/g, " "));
};
export const getValidSpaces = (name) => {
  if (!name) return "";
  return name.replace(/  +/g, " ");
};

export const isValidName = (name) => {
  if (!name) return false;
  return true;
  if (new RegExp(/^(?!.*([a-z])\1{1})[A-Za-z ]{1,75}$/i).test(name)) {
    return true;
  } else return false;
};
export const isValidPhone = (phone) => isValidNumber(phone, "US");
export const validatePhone = (value) => {
  if (value) return isPossiblePhoneNumber(value);
  return false;
};

export const getPageNumbers = (items) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(items.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }
  return pageNumbers;
};
export const isValidDob = (dobs) => {
  if (new RegExp(/^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/).test(dobs)) {
    return true;
  }
  return false;
};

export const isValidString = (str) => {
  if (new RegExp(/[a-z]/g).test(str)) {
    return true;
  }
  return false;
};

export const calcHeight = (value, width) => {
  const lineHeight = 20; // Adjust this value based on your line-height CSS

  // Calculate the number of lines based on the width and line breaks
  const textareaWidth = width; // Subtract padding and border widths
  const lineBreaks = (value.match(/\n/g) || []).length;
  const numberOfLines = Math.floor((value.length * lineHeight) / width) + lineBreaks;
  // Calculate the new height
  const newHeight = numberOfLines * lineHeight;
  return newHeight;
};

export const isValidDobField = (dob, format) => {
  if (!dob) return false;
  return moment(dob, `${format}`, true).isValid();
};
export const parseBooleanValue = (val) => (val ? "Yes" : "No");
export const getVaccinated = (vac) => {
  if (!vac) return false;
  const val = vac.toLowerCase();
  if (val) return val === "y" || val === "yes" ? true : false;
  return false;
};
export const capitalizeLetter = (value) => {
  if (!value) return "";
  if (value.length === 2) return value.toUpperCase();
  return `${value.substring(0, 1).toUpperCase()}${value.substring(1).toLowerCase()}`;
};

export const getTimeZonesArr = () => {
  return momentTZ.tz.names();
};

export const getCrewsName = (empDemos) => {
  if (!empDemos) return "";
  let parsed = typeof empDemos === "string" ? JSON.parse(empDemos) : empDemos;
  let first = parsed.firstName.charAt(0).toUpperCase() + parsed.firstName.slice(1).toLowerCase();
  let last = parsed.lastName.charAt(0).toUpperCase() + parsed.lastName.slice(1).toLowerCase();
  return `${first} ${last}`;
};
export const onBoardingTest = (test) => {
  if (Array.isArray(test)) return [...test];
  if (test instanceof Object) return [{ ...test }];
  return [];
};

export const formatInvoice = (items) => {
  try {
    return items.map((obj) => {
      let item = { ...obj };
      item["toData"] = parseObject(item, "toData");
      item["details"] = parseObject(item, "details");

      return { ...item, discount: item.discount || 0 };
    });
  } catch (ex) {
    console.log("[formatInvoice]", ex);
  }
  return items;
};

export const emailMsgToCreatedMember = (firstName, userName, password) => {
  const msg = `Thank you ${firstName} for signing up with SafeCamp LTC powered by Tackl Health. We are excited to partner with you and help you explore ways to stay safe and monitor your health.<br><br>

        Please login to <a href="https://www.ltc.safecampmd.com/member-login">https://www.ltc.safecampmd.com/member-login</a> To view your profile, order tests and update your insurance information.<br><br>

        Username: ${userName}<br>
        Password: ${password}<br><br>

        We thank you again for choosing us to service your critical healthcare needs<br><br>

        Best Regards,<br>
        SafeCamp LTC Team`;

  return msg;
};

export const emailMsgToSubAgent = (firstName, userName, password) => {
  const msg = `Thank you ${firstName} for signing up with SafeCamp LTC powered by Tackl Health. We are excited to partner with you and help you explore ways to stay safe and monitor your client’s and members health.<br><br>
  
  Please login to <a href="${CONFIG.appURL}">${CONFIG.appURL}</a> To order tests, add members, track shipments, and update any information required.<br><br>
  
  Username: ${userName}<br>
  Password: ${password}<br><br>

  We thank you again for choosing us to service your critical healthcare needs<br><br>

  Best Regards,<br>
  SafeCamp LTC Team`;

  return msg;
};

export const emailMsgToCreatedFacility = (firstName, userName, password) => {
  const msg = `Thank you ${firstName} for signing up with SafeCamp LTC powered by Tackl Health. We are excited to partner with you and help you explore ways to stay safe and monitor your client’s and members health.<br><br>
  
  Please login to <a href="${CONFIG.appURL}">${CONFIG.appURL}</a> To order tests, add members, add sub agents, track shipments, and update any information required.<br><br>
  
  Username: ${userName}<br>
  Password: ${password}<br><br>

  We thank you again for choosing us to service your critical healthcare needs<br><br>

  Best Regards,<br>
  SafeCamp LTC Team`;

  return msg;
};

export const emailMsgToCreatedClient = (firstName, userName, password) => {
  const msg = `Thank you ${firstName}  for signing up with SafeCamp LTC powered by Tackl Health. We are excited to partner with you and help you explore ways to stay safe and monitor your client’s and members health.<br><br>
  
  Please login to <a href="${CONFIG.appURL}">${CONFIG.appURL}</a> To create agents/facilities, order tests, add members, track shipments, and update any information required<br><br>
  
  Username: ${userName}<br>
  Password: ${password}<br><br>

  We thank you again for choosing us to service your critical healthcare needs<br><br>

  Best Regards,<br>
  SafeCamp LTC Team`;

  return msg;
};

export const formatOrder = (obj, employees) => {
  const address = [];
  const orderObj = { ...obj };
  const emp = (employees || []).find((f) => f.id === obj.employeeID);
  orderObj.details = typeof orderObj.details === "string" ? JSON.parse(orderObj.details) : orderObj.details;
  address.push(orderObj.details?.street || "");
  if (orderObj.details?.street2) address.push(orderObj.details?.street2 || "");
  address.push(orderObj.details?.city || "");
  return {
    ...orderObj,
    lastName: orderObj.lastName,
    firstName: orderObj.firstName,
    phoneNumber: orderObj.phoneNumber ? `${orderObj.countryCode}${orderObj.phoneNumber}` : "",
    email: orderObj.email,
    siteName: orderObj.locationName,
    medicalNo: orderObj.medicalNo,
    orderId: orderObj.orderId === "-1" ? "-" : orderObj.orderId,
    address: address.join(","),
    employeeClaimID: orderObj.employeeClaimID || "",
    claim: orderObj.claimSubmitDate ? "1" : "0",
    employeeStatus: emp?.status,
    note: {
      ...(orderObj.note ? orderObj.note : {}),
      message: emp?.note || orderObj.note?.message || "",
    },
  };
};

export const formatTest = (testData, clients) => {
  let demos =
    typeof testData.employee_demographics === "string"
      ? JSON.parse(testData.employee_demographics)
      : testData.employee_demographics;
  let name;
  let firstName;
  let lastName;
  if (demos) {
    name = demos.firstName + " " + demos.lastName;
    firstName = capitalizeLetter(demos.firstName);
    lastName = capitalizeLetter(demos.lastName);
  }
  const test = { ...testData };
  test.viewedResult = testData.result;
  if (clients && clients.length > 0) {
    const client = clients.find((c) => c.id === test.clientID);
    if (client && client.resultType === "P/F") {
      test.viewedResult = AMAZON_CLIENT_RESULT[test.result];
    }
  }

  test.status = testData.status.toLowerCase() === "pending" && !testData.result ? "New" : testData.status;
  test.test_type =
    testData.test_type === "Antigen"
      ? "Rapid Antigen"
      : testData.test_type === "Other"
      ? "Rapid Accula"
      : testData.test_type;
  return { ...test, name, firstName, lastName };
};

export const getDropDownValue = (val) => {
  if (val === null || val === undefined) return null;
  if (val) return { value: true, label: "Yes" };
  return { value: false, label: "No" };
};
export const formatQuestion = (ques) => {
  return ques.map((q) => ({ ...q, question: JSON.parse(q.question) }));
};
export const getDemoGraphics = (test) =>
  typeof test.employee_demographics === "string" ? JSON.parse(test.employee_demographics) : test.employee_demographics;

export const formatDateOfBirth = (dob) =>
  dob ? `${dob.substring(0, 2)}/${dob.substring(2, 4)}/${dob.substring(4)}` : "";

export const getAge = (dob) => {
  const val = formatDateOfBirth(dob);
  return dob ? moment().diff(val, "years") : "";
};
export const getValidGender = (gen) => {
  if (gen.toLowerCase() == "male" || gen.toLowerCase() == "m") return "M";
  if (gen.toLowerCase() == "female" || gen.toLowerCase() == "f") return "F";
  if (gen.toLowerCase() == "x") return "X";
  return "";
};

export const formatDate = (date) => (date ? moment(date).format("MMM DD, YYYY") : "");

export const formatDateMDY = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");

export const formatDateMDYTime = (date) => (date ? moment(date).format("MM/DD/YYYY HH:mm") : "");

export const currentDate = () => moment().format("MM/DD/YYYY");

export const getDifferentColors = (colorNum, colors, opacity = 1) => {
  if (colors < 1) colors = 1; // defaults to one color - avoid divide by zero
  return "hsl(" + ((colorNum * (360 / colors)) % 360) + ",100%,50%," + opacity + ")";
};

export const formatPDFName = (demos) => {
  let name = "";
  if (demos.lastName) name = `${demos.lastName.substring(0, 1)}${demos.lastName.substring(1).toLowerCase()}`;
  if (name) name = `${name}, `;
  if (demos.firstName) name = `${name}${demos.firstName.substring(0, 1)}${demos.firstName.substring(1).toLowerCase()}`;
  return name;
};

export const parseClaimDate = (date) => {
  if (!date) return "";
  return moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
};

export const formatZip = (val) => {
  if (!val) return "";
  const input = val.replace(/\D/g, "");
  if (input.toString().length > 5) {
    return input.substring(0, 5);
  }
  if (input.toString().length === 5) {
    return input.toString();
  }
  return input;
};

const parseObject = (obj, key) => {
  return typeof obj[key] === "string" ? JSON.parse(obj[key]) : obj[key];
};

export const formatCurrency = (val) => {
  if (!val) return "$0.00";
  const obj = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return obj.format(val);
};
export const parseCurrency = (val) => {
  if (!val) return 0;
  const parsedValue = val.toString().replace(/[^0-9.]/g, "");
  return parseFloat(parsedValue) || 0;
};
export const formatClaims = (models) => {
  let claims = [...models];
  claims = claims.map((obj) => {
    let m = { ...obj };
    m["provider"] = parseObject(m, "provider");
    m["proc_array"] = parseObject(m, "proc_array");
    m["acknowledgeBy"] = parseObject(m, "acknowledgeBy");
    m["assign_details"] = parseObject(m, "assign_details");
    m["paid_insurance"] = parseObject(m, "paid_insurance");
    m["additional"] = parseObject(m, "additional");

    if (m.status?.toLowerCase() === CLAIM_SUBMIT_STATUS.submitted) {
      m.status = CLAIM_SUBMIT_STATUS.sent;
    }

    let subBy = m.submittedByName || m.updatedByName;
    if (subBy === "0") subBy = m.updatedByName;

    const toData = m.toData && typeof m.toData === "string" ? JSON.parse(m.toData) : m.toData ?? [];

    let daysOpen = m.daysOpen ?? moment().diff(moment(m.reSubmissionDate), "days");

    const isInvoiceDraftClaim = m.status && m.status === "draft" && BILL_PATIENT_INS.includes(m.payerid);

    const claimStatus = isInvoiceDraftClaim ? "open" : m.status;

    return {
      ...m,
      status: claimStatus,
      invoiceID: toData?.find((f) => f.invoiceID)?.invoiceNo,
      invoiceAmount: toData?.find((f) => f.invoiceID)?.invoiceAmount,
      toData,
      ins_number: CUSTOM_INS.includes(m.payerid) ? "" : m.ins_number,
      partialAmount: m.status?.toLowerCase() === CLAIM_SUBMIT_STATUS.partial ? true : false,
      reSubmissionDate: m.reSubmissionDate || m.createdAt,
      paid_ins_processed: m.paid_insurance?.payer_name || "",
      submittedByName: subBy,
      invoiceStatus: toData?.find((f) => f.invoiceID)?.status || "pending",
      daysOpen: daysOpen,
      avgDaysToPay: 21,
    };
  });
  return claims;
};

export const changeSort = (
  sortBy,
  sortDescending,
  filteredTests,
  setFilteredTests,
  getSiteName,
  getLabName,
  getClientName,
  parseTestResult
) => {
  switch (sortBy) {
    case "employee_demographics":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
                ? -1
                : 0
            )
      );
      break;
    case "clientID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) < getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) < getClientName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) > getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) > getClientName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "siteID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) < getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) < getSiteName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) > getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) > getSiteName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "labID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) < getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) < getLabName(b[sortBy]) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) > getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) > getLabName(b[sortBy]) ? -1 : 0
            )
      );
      break;
    case "result":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              parseTestResult(b) < parseTestResult(a) ? 1 : parseTestResult(a) < parseTestResult(b) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              parseTestResult(b) > parseTestResult(a) ? 1 : parseTestResult(a) > parseTestResult(b) ? -1 : 0
            )
      );
      break;
    case "qaDone":
    case "testDone":
    case "checkIn":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] || "";
              const testerA = a[sortBy] || "";
              return testerB.toString() < testerA.toString() ? 1 : testerA.toString() < testerB.toString() ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] || "";
              const testerA = a[sortBy] || "";
              return testerB.toString() > testerA.toString() ? 1 : testerA.toString() > testerB.toString() ? -1 : 0;
            })
      );
      break;
    case "eligibilityStatus":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy]?.message || "";
              const testerA = a[sortBy]?.message || "";
              return testerB.toString() < testerA.toString() ? 1 : testerA.toString() < testerB.toString() ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy]?.message || "";
              const testerA = a[sortBy]?.message || "";
              return testerB.toString() > testerA.toString() ? 1 : testerA.toString() > testerB.toString() ? -1 : 0;
            })
      );
      break;
    case "employeeID":
    case "status":
    case "callTime":
    case "isVaccinated":
    case "programName":
    case "zoneColor":
    case "localNo":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "lastName":
    case "tester_name":
    case "tests":
    case "clientName":
    case "siteName":
    case "locationName":
    case "subAgentName":
    case "gender":
    case "medicareNo":
    case "medicalNo":
    case "insuranceGroupId":
    case "onBoardingTesting":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ?? "";
              const testerA = a[sortBy] ?? "";
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
      break;
    case "totalTests":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              b.tests.length < a.tests.length ? 1 : a.tests.length < b.tests.length ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              b.tests.length > a.tests.length ? 1 : a.tests.length > b.tests.length ? -1 : 0
            )
      );
      break;
    default:
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy];
              const testerA = a[sortBy];
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy];
              const testerA = a[sortBy];
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
  }
};

export const sortList = (sortBy, sortDescending, filteredTests) => {
  switch (sortBy) {
    case "employee_demographics":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
              ? -1
              : 0
          );
    case "qaDone":
    case "testDone":
    case "checkIn":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] || "";
            const testerA = a[sortBy] || "";
            return testerB.toString() < testerA.toString() ? 1 : testerA.toString() < testerB.toString() ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] || "";
            const testerA = a[sortBy] || "";
            return testerB.toString() > testerA.toString() ? 1 : testerA.toString() > testerB.toString() ? -1 : 0;
          });
    case "eligibilityStatus":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy]?.message ?? "";
            const testerA = a[sortBy]?.message ?? "";
            return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy]?.message ?? "";
            const testerA = a[sortBy]?.message ?? "";
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });

    case "employeeID":
    case "status":
    case "test_type":
    case "barcode":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "clientName":
    case "siteName":
    case "locationName":
    case "subAgentName":
    case "lastName":
    case "departmentName":
    case "tester_name":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });
    case "totalTests":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            b.tests.length < a.tests.length ? 1 : a.tests.length < b.tests.length ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            b.tests.length > a.tests.length ? 1 : a.tests.length > b.tests.length ? -1 : 0
          );
    default:
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });
  }
};

export const isValidFile = (file) => {
  const fileName = file.name;

  const exts = ["png", "jpg", "jpeg", "gif"];

  if (fileName) {
    let getExt = fileName.split(".");
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return "only image files are allowed";
    }

    if (file.size / 1024 / 1020 > 10) {
      return "max. 10MB file size allow";
    }

    return "";
  }
  return "";
};
export const formatDateMatrix = (d) => moment(d).format("ddd MM/DD");

export const importPhoneNoFormat = (phone) => {
  if (!phone) return;

  // Remove all non-digit characters
  const formattedPhoneNo = phone.replace(/\D/g, "");

  // Check the length of the formatted number
  if (formattedPhoneNo.length === 10) {
    // Format as +1XXXXXXXXXX for 10-digit numbers
    return `+1${formattedPhoneNo}`;
  } else if (formattedPhoneNo.length === 11 && formattedPhoneNo.startsWith("1")) {
    // Format as +1XXXXXXXXXX for 11-digit numbers starting with 1
    return `+${formattedPhoneNo}`;
  } else if (formattedPhoneNo.length === 12) {
    // Format as +CCXXXXXXXXXX for 12-digit numbers
    return `+${formattedPhoneNo}`;
  }
  return formattedPhoneNo;
};

export const searchPhone = (phone) => {
  if (!phone) return null;
  return phone.includes("+") ? `${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};
export const setPhoneNo = (phone) => {
  if (!phone) return null;
  return phone.includes("+1") ? `${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};
export const getPhoneNumber = (phone) => {
  if (!phone) return "";
  const countryCode = phone.substring(0, 1);

  if (countryCode !== "+") {
    const phoneNumber = `+${phone.replace(/\D/g, "")}`;
    return phoneNumber;
  }
  return phone;
};

export const insuranceProviderFormatter = (insuranceCompany) => {
  if (!insuranceCompany) return "-";
  const item = INSURANCE_PROVIDER.find((i) => i.value === insuranceCompany);
  if (!item) return "-";
  return item?.label;
};

export const getStateLength = (obj) => {
  if (
    !obj.country ||
    obj.isoCode === "US" ||
    obj.isoCode === "CA" ||
    obj.country === "United States" ||
    obj.country === "Canada"
  ) {
    return 2;
  }
  return 8;
};

export const formatProvidersLabel = (name = "", code = "") => {
  return name;

  // let paddedCode = (code || "").padEnd(6, " "); // Pad the code to the right up to 6 characters
  let codeLength = code ? code.length : 1; // Get the length of the code or default to 1 if code is not present
  let paddedCode = (code || "-").padEnd(6, " "); // Pad the code to the right up to 6 characters

  // If the code's original length is less than 6, add extra padding
  if (codeLength < 6) {
    paddedCode = paddedCode + " ".repeat(6 - codeLength);
  }
  return paddedCode + " " + name;
};

export const formatZipCode = (id) => {
  if (!id) return id;
  let val = id.split("-").join("");
  return val.match(/.{1,5}/g).join("-");
};

export const isValidLocation = (loc) => {
  if (!loc) return null;
  const val = loc.toLowerCase();
  return LOCATION.includes(val);
};

export const isValidRegion = (reg) => {
  if (!reg) return null;
  const val = reg.toLowerCase();
  return REGION.includes(val);
};

export const getInitials = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0))
    .join("");
};

export const medFlowInLocalStorage = {
  save: () => localStorage.setItem("medFlowHR", "medFlow"),
  get: () => localStorage.getItem("medFlowHR") || null,
  clear: () => localStorage.clear(),
};

export const loggedInUser = {
  save: (obj) => localStorage.setItem("ltcUserInfo", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("ltcUserInfo")) {
      return JSON.parse(localStorage.getItem("ltcUserInfo"));
    }
    return {
      name: "",
      email: "",
      phone: "",
    };
  },
  clear: () => localStorage.removeItem("ltcUserInfo"),
};

export const LSProcessPaymentData = {
  save: (obj) => localStorage.setItem("brmProcessPayment", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("brmProcessPayment")) {
      return JSON.parse(localStorage.getItem("brmProcessPayment"));
    }
    return null;
  },
  clear: () => localStorage.removeItem("brmProcessPayment"),
};

export const patientDataInLocalStorage = {
  save: (obj) => localStorage.setItem("client", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("client")) {
      return JSON.parse(localStorage.getItem("client"));
    }
    return newRegisterObj;
  },
  clear: () => localStorage.clear(),
};

export const sortingFilterInLC = {
  save: (obj) => localStorage.setItem("medflowhr", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("medflowhr")) {
      return JSON.parse(localStorage.getItem("medflowhr"));
    }
    return {
      employees: {},
      clients: {},
      orders: {},
      facilities: {},
      users: {},
      subAgents: {},
    };
  },
  clear: () => localStorage.removeItem("medflowhr"),
};

export const userCompanyID = {
  save: (id) => localStorage.setItem("cid", id),
  get: () => localStorage.getItem("cid") || null,
  clear: () => localStorage.removeItem("cid"),
};
export const userCompanyLogo = {
  save: (img) => localStorage.setItem("ucl", img),
  get: () => localStorage.getItem("ucl") || null,
  clear: () => localStorage.removeItem("ucl"),
};

export const formatDateOfBirthDOB = (dob) => {
  if (!dob || dob == "") return "";
  if (dob.length === 8) {
    const month = dob.substring(0, 2);
    const day = dob.substring(2, 4);
    const year = dob.substring(4);
    return moment(`${year}${month}${day}`).format("MM/DD/YYYY");
  } else return moment(dob).format("MM/DD/YYYY");
};

export const formatDOB = (dobs) => {
  if (!dobs) return "";
  const dob = dobs.split(/\/|-|,/g);
  if (dob[0].length === 1) {
    dob[0] = "0" + dob[0];
  }
  if (dob[1].length === 1) {
    dob[1] = "0" + dob[1];
  }
  return moment(`${dob[2]}-${dob[0]}-${dob[1]}`).format("YYYY-MM-DD");
};

export const formatInsuranceDate = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");

export const calculateTdWidth = (width, numberOfCol) =>
  screen.width >= "768" && screen.width <= "1024" ? 100 : Math.ceil(width / numberOfCol);

export const personalizationLocalStorage = {
  save: (obj) => localStorage.setItem("personalisation", obj),
  saveAs: (arr, key) => {
    const selectedSetting = arr
      .filter((f) => f.isCheck)
      .map((f) => f.id)
      .join(",");

    const objData = JSON.parse(localStorage.getItem("personalisation"));
    objData[key] = selectedSetting;
    localStorage.setItem("personalisation", JSON.stringify(objData));
    return objData;
  },
  get: (user, key, NEW_PERSONALIZE) => {
    let arrData = null; //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","21","22"];
    let objData = null;
    try {
      const lcObj = localStorage.getItem("personalisation");
      if (lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("personalisation");
      } else {
        return NEW_PERSONALIZE;
      }
      objData = JSON.parse(objData);
      arrData = objData[key].split(",");
    } catch (err) {}

    return NEW_PERSONALIZE.map((f) => {
      return { ...f, isCheck: arrData?.indexOf(f.id.toString()) !== -1 };
    });
  },
  clear: () => localStorage.removeItem("personalisation"),
};

export const updateEmployeeClaimPersonalization = (columsArray, secondArrayKey) => {
  const firstArray = columsArray[secondArrayKey] || columsArray.default;
  const lcObj = localStorage.getItem("personalisation");
  const parsedObj = lcObj && lcObj !== "undefined" ? JSON.parse(lcObj) : {};

  let secondArray = JSON.parse(parsedObj[secondArrayKey] || "[]");

  if (!Array.isArray(secondArray)) {
    secondArray = [];
  }

  const noColumnSelceted = secondArray.length === 0;

  // in case no personalization is selected in claim tab set the default
  const defaultIndexs = [0, 1, 2, 10, 11, 12, 13, 19, 21];
  if (noColumnSelceted) {
    firstArray.forEach((f, index) => {
      if (defaultIndexs.indexOf(index) !== -1) {
        secondArray.push({
          ...f,
          isCheck: true,
        });
      }
    });
  }

  const secondArrayMap = new Map();
  secondArray.forEach((item, index) => {
    secondArrayMap.set(item.id, { width: item.width, order: index });
  });

  const updatedArray = firstArray.map((item) => ({
    ...item,
    width: secondArrayMap.has(item.id) ? secondArrayMap.get(item.id).width : item.width,
    isCheck: secondArrayMap.has(item.id),
  }));

  updatedArray.sort((a, b) => {
    const orderA = secondArrayMap.has(a.id) ? secondArrayMap.get(a.id).order : Infinity;
    const orderB = secondArrayMap.has(b.id) ? secondArrayMap.get(b.id).order : Infinity;
    return orderA - orderB;
  });

  return updatedArray;
};

export const draggablePersonalizationLocalStorage = {
  save: (obj) => localStorage.setItem("personalisation", obj),
  saveAs: (arr, key) => {
    const selectedSetting = JSON.stringify(
      arr
        .filter((f) => f.isCheck)
        .map((f) => {
          return { id: f.id, width: f.width };
        })
    );

    const objData = JSON.parse(localStorage.getItem("personalisation"));
    objData[key] = selectedSetting;
    localStorage.setItem("personalisation", JSON.stringify(objData));
    return objData;
  },
  get: (user, key, NEW_PERSONALIZE) => {
    let arrData = null; //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","21","22"];
    let objData = null;
    try {
      const lcObj = localStorage.getItem("personalisation");
      if (lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("personalisation");
      } else {
        return NEW_PERSONALIZE;
      }
      objData = JSON.parse(objData);
      arrData = JSON.parse(objData[key]);
    } catch (err) {}

    if (!Array.isArray(arrData)) return NEW_PERSONALIZE;

    let objToReturn = arrData?.map((f) => {
      const obj = NEW_PERSONALIZE.find((obj) => obj.id == f.id) || null;
      return { ...obj, isCheck: NEW_PERSONALIZE.findIndex((obj) => obj.id == f.id) !== -1, width: f.width };
    });

    const lsData = arrData?.map((obj) => obj.id);
    if (!lsData) return NEW_PERSONALIZE;
    const nonCheckedData = NEW_PERSONALIZE.filter((obj) => !lsData.includes(obj.id));
    objToReturn = [
      ...objToReturn,
      ...nonCheckedData.map((data) => {
        return { ...data, isCheck: false, width: "100%" };
      }),
    ];
    return objToReturn;
  },
  clear: () => localStorage.removeItem("personalisation"),
};

export const tdCheckBoxIcon = (icon, colorTheme, shape, size) => {
  return (
    <td style={{ textAlign: "center", textOverflow: "visible" }}>
      <div className="star-icon-wrapper">
        <Status
          type={shape}
          size={size}
          color={colorTheme}
          crossIcon={icon === CHECKBOX_ICON_OPTIONS.cross}
          tickIcon={icon === CHECKBOX_ICON_OPTIONS.tick}
          questionIcon={icon === CHECKBOX_ICON_OPTIONS.question}
        />
      </div>
    </td>
  );
};

export const tdEmail = (email) => (
  <td
    className=" ellipsis"
    style={{
      textDecoration: "underline",
      color: "var(--text-danger)",
    }}
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => email && window.open(`mailto:${email}`)}
    title={email}
  >
    {email}
  </td>
);

export const tdRowDropdown = (showDropdown, isOpen, handleClick, cssClass) => {
  return (
    <td className={cssClass ?? ""}>
      {showDropdown && (
        <i
          onMouseLeave={(e) => (e.target.style.color = "inherit")}
          onMouseOver={(e) => {
            e.target.style.color = "#8B0000";
            e.target.style.cursor = "pointer";
          }}
          onClick={handleClick}
          aria-hidden="true"
          className={isOpen ? "fas fa-minus" : "fas fa-plus"}
        />
      )}
    </td>
  );
};

export const getHours = (time) => {
  return time.split(":")[0] + ":00";
};

export const formatOrderedDate = (date) =>
  date ? `${date.substring(4, 6)}/${date.substring(6, 8)}/${date.substring(0, 4)}` : "";
export const formatOrderedDateAws = (date) =>
  date ? `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}` : "";

export const tdOrderDate = (item, user) => {
  return (
    <td
      className="ellipsis"
      style={{
        textAlign: item.textAlign,
        textOverflow: item.textOverflow,
      }}
      title={formatOrderedDate(user.orderDate)}
    >
      {formatOrderedDate(user.orderDate)}
    </td>
  );
};

export const tdPhone = (phoneNumber, textAlign, key = "") => (
  <td
    className="ellipsis"
    style={{
      textDecoration: "underline",
      color: "var(--text-danger)",
      textAlign: textAlign || "",
    }}
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => phoneNumber && window.open(`tel:+${phoneNumber.replace(/\D/g, "")}`)}
    title={formatPhoneNumber(phoneNumber)}
  >
    {formatPhoneNumber(phoneNumber)}
  </td>
);

export const tdLink = (item, dates, itemID, filterKey) => (
  <td
    className="ellipsis"
    style={{
      textAlign: "center",
      textOverflow: "none",
    }}
  >
    {item > 0 ? (
      <Link
        style={{ color: "#42cef5" }}
        to={{
          pathname: "/admin/orders",
          state: {
            name: itemID,
            term: filterKey,
            filterName: dates,
            filterTerm: dates,
          },
        }}
      >
        {formatNumber(item)}
      </Link>
    ) : (
      item
    )}
  </td>
);

export const tdTrackingID = (number) => (
  <td
    className="ellipsis"
    style={{
      textDecoration: "underline",
      color: "#A82632",
    }}
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() =>
      number && window.open(`https://www.stamps.com/tracking-details/?t=${number.replace(/\D/g, "")}`, "_blank")
    }
    title={number}
  >
    {number}
  </td>
);

export const tdCheckBox = (item, user, openExternalTest) => {
  return <td style={{ textAlign: "center", textOverflow: "visible" }}>-</td>;
};

export const tdProgramStatus = (item, user) => {
  return (
    <td
      style={{ textAlign: "center", textOverflow: "visible" }}
      title={
        user.isSchedule === STATUS.startProgram
          ? "Program Started"
          : user.isSchedule === STATUS.assignProgram
          ? "Program Assigned"
          : "Program Unassigned"
      }
    >
      {user.isSchedule === STATUS.startProgram || user.isSchedule === STATUS.zoneAssign ? (
        <ViewCheckBox id="filter" bgColor="green" />
      ) : user.isSchedule === STATUS.assignProgram ? (
        <ViewCheckBox id="filter" bgColor="yellow" />
      ) : (
        "-"
      )}
    </td>
  );
};
export const tdZoneColor = (item, user) => {
  return (
    <td
      style={{
        textAlign: item.textAlign,
        textOverflow: item.textOverflow,
      }}
      title={user.zoneColor}
    >
      {user.zoneColor ? (
        <div
          className="m-auto"
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: `${removeSpaceIns(user.zoneColor)}`,
          }}
        ></div>
      ) : (
        ""
      )}
    </td>
  );
};
export const formatTimeZone = (Tz) => {
  if (!Tz) return "";
  if (Tz === "Pakistan Standard Time") return "PKT";
  return Tz.match(/\b\w/g).join("");
};
export const statusOptions = () => {
  return [
    { value: "0", label: "Empty" },
    {
      value: "1",
      label: <ViewCheckBox id="filter" bgColor="green" />,
    },
    {
      value: "2",
      label: <ViewCheckBox id="filter" bgColor="yellow" />,
    },
  ];
};

export const isValidEmailAttachment = (file) => {
  const fileName = file.name;

  const exts = ["png", "jpg", "jpeg", "gif", "pdf", "csv", "xls", "xlsx", "doc", "docx", "txt"];

  if (fileName) {
    let getExt = fileName.split(".");
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return "only image files, PDF, CSV are allowed";
    }

    if (file.size > 10000000) {
      return "Maximum file size should be 10MB!";
    }

    return "";
  }
  return "";
};

export const formatFileSize = (size) => {
  if (size === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const formatNumberWithDecimal = (num) => {
  const parsedNumber = parseFloat(num);
  if (isNaN(parsedNumber)) {
    return num;
  }

  const hasDecimal = /\./.test(num);
  const formattedNumber = hasDecimal ? parsedNumber.toFixed(2) : `${parsedNumber}.00`;
  return formattedNumber;
};

export const formatNumber = (num) => {
  num = parseInt(num);
  if (!num) return 0;
  return num.toLocaleString("en-US");
};
export const formatPercentage = (val) => {
  if (!val) return "0%";

  // Multiply the value by 100 to convert it to a percentage and round it to two decimal places.
  // const percentageValue = (val * 100).toFixed(2);
  const percentageValue = Math.abs(val) < 1 ? (val * 100).toFixed(2) : val.toFixed(2);
  const obj = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return obj.format(percentageValue / 100);
};
export const formatEmployeesData = (employees = [], subAgents = [], locations = [], companies = [], providers = []) => {
  if (employees.length === 0) return [];
  
  return employees.map((emp) => {
    const subAgent = subAgents?.find((s) => s.id === emp.subAgent);
    const location = locations?.find((s) => s.id === emp.companyID);
    const client = companies?.find((c) => c.id === emp.clientID);
    const provider = providers?.find((p) => p.id === emp.renderingProvider);
    const refProvider = providers?.find((p) => p.id === emp.referringProvider);
    const ordProvider = providers?.find((p) => p.id === emp.orderingProvider);
    let insuranceDetails = emp.insuranceDetails;
    let primaryInsurance = emp.primaryInsurance;
    let setting = emp.setting;
    let codes = emp.codes;

    if (insuranceDetails && typeof insuranceDetails === "string") {
      insuranceDetails = JSON.parse(insuranceDetails);
    }
    if (primaryInsurance && typeof primaryInsurance === "string") {
      primaryInsurance = JSON.parse(primaryInsurance);
    }
    if (setting && typeof setting === "string") {
      setting = JSON.parse(setting);
    }
    if (codes && typeof codes === "string") {
      codes = JSON.parse(codes);
    }

    return {
      ...emp,
      eligibilityStatus: parseStringToJSON(emp.eligibilityStatus),
      connectedTo: parseStringToJSON(emp.connectedTo),
      phoneNumber: `${emp.countryCode}${emp.phoneNumber}`,
      subAgent: subAgent ? { ...subAgent, value: subAgent.id, label: subAgent.name } : null,
      location: location ? { ...location, value: location.id, label: location.name } : null,
      client: client ? { ...client, value: client.id, label: client.name } : null,
      gender: emp.sex,
      secondaryInsurance: emp.insuranceDetails?.insuranceCompany || "",
      secondaryInsNumber: emp.insuranceDetails?.medicalNo || "",
      clientName: client ? client.name : "N/A",
      locationName: location ? location.name : "N/A",
      subAgentName: subAgent ? subAgent.name : "N/A",
      subAgentID: subAgent ? subAgent.id : null,
      providerName: provider?.name,
      providerFirstName: provider?.firstName,
      providerLastName: provider?.lastName,
      providerMidName: provider?.middleName,
      providerID: provider?.id,
      providerNpi: provider?.npi,
      providerTaxId: provider?.taxId,
      refProviderID: refProvider?.id,
      refProviderFirstName: refProvider?.firstName,
      refProviderName: refProvider?.name,
      refProviderLastName: refProvider?.lastName,
      refProviderMiddleName: refProvider?.middleName,
      refProviderNpi: refProvider?.npi,
      ordProviderID: ordProvider?.id,
      ordProviderFirstName: ordProvider?.firstName,
      ordProviderName: ordProvider?.name,
      ordProviderLastName: ordProvider?.lastName,
      ordProviderMiddleName: ordProvider?.middleName,
      ordProviderNpi: ordProvider?.npi,
      insuranceDetails,
      setting,
      primaryInsurance,
      codes,
    };
  });
};

export const formatLocations = (locations, companies) => {
  if (locations.length === 0) return [];

  return locations.map((emp) => {
    const client = companies.find((s) => s.id === emp.companyID);

    return {
      ...emp,
      setting: parseStringToJSON(emp.setting),
      clientName: client ? client.name : "N/A",
      client,
    };
  });
};

export const getValidName = (name) => {
  if (!name) return "";
  return toTitleCase(name.replace(/  +/g, " "));
};

export const isValidIdNum = (id) => {
  if (!id) return false;
  if (new RegExp(/^(?!.*([a-zA-Z0-9])\1{4})[a-zA-Z0-9]{5,16}$/).test(id)) {
    return true;
  } else return false;
};
export const isValidChequeNumber = (id) => {
  if (!id) return false;
  if (new RegExp(/^\d{6}$/).test(id)) {
    return true;
  } else return false;
};
export const isValidAmount = (id) => {
  if (!id) return false;
  if (new RegExp(/^\d+(\.\d+)?$/).test(id)) {
    return true;
  }
  return false;
};

export const isValidPhoneWithCode = (phone) => {
  if (phone) return isPossiblePhoneNumber(phone);
  return false;
};

export const formatOrderDate = (date) => (date ? moment(date).format("MM-DD-YYYY hh:mm A") : "");

export const isValidIDNumber = (val) => {
  if (!val) return false;
  if (new RegExp(/^(?=.*[0-9])([a-zA-Z0-9]{8,})+$/).test(val)) {
    return true;
  }
  return false;
};

export const isValidZipCode = (code, zip) => {
  if (!zip) return false;
  const validate = postalCodes.validate(`${code}`, `${zip}`);

  return typeof validate !== "string" ? validate : false;
};

export const getPhoneNo = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }

    const phone = formatPhoneNumberIntl(phone_number);
    const phoneArr = phone.split(" ");
    const countryCode = phoneArr[0];
    phoneArr.splice(0, 1);
    const phoneNo = phoneArr.join("");
    return [countryCode, phoneNo, phone];
  } catch (err) {
    console.log("Error", err);
  }
  return ["", phone_number_value, phone_number_value];
};

export const getPreRegistrationLink = (id, type = "") => {
  if (CONFIG.isLive) {
    return `https://ltc.safecampmd.com/order-test${type}/${id}`;
  }
  return `https://staging.ltc.safecampmd.com/order-test${type}/${id}`;
};

export const isValidUrl = (file) => {
  if (!file) return false;
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = typeof file === "object" ? URL.createObjectURL(file) : file;
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      resolve(false);
    };
  });
};

export const DataFilteringBinarySearch = (searchQuery, data) => {
  let start = 0;
  let end = data.length - 1;
  let mid;

  while (start <= end) {
    mid = Math.floor((start + end) / 2);

    if (data[mid].value.toLowerCase().includes(searchQuery.toLowerCase())) {
      break;
    }

    if (data[mid].value.toLowerCase() < searchQuery.toLowerCase()) {
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }

  if (start <= end) {
    const filteredData = [];

    let i = mid;

    while (i >= 0 && data[i].value.toLowerCase().includes(searchQuery.toLowerCase())) {
      filteredData.unshift(data[i]);
      i--;
    }

    i = mid + 1;

    while (i < data.length && data[i].value.toLowerCase().includes(searchQuery.toLowerCase())) {
      filteredData.push(data[i]);
      i++;
    }

    return filteredData;
  }

  return [];
};

const addCompanyInusranceNPI = (modifiedObj, setting) => {
  if (modifiedObj.bill_npi || setting) {
    const detailsOfNpi =
      (modifiedObj.bill_npi && setting.otherNPI?.find((f) => f.npi === modifiedObj.bill_npi)) || setting;

    if (detailsOfNpi) {
      const { name, npi, phoneNumber, state, street, street2, city, taxid, taxonomyType, zip, bill_id } = detailsOfNpi;

      modifiedObj["bill_name"] = name;
      modifiedObj["bill_npi"] = npi;
      modifiedObj["bill_phone"] = phoneNumber;
      modifiedObj["bill_state"] = state;
      modifiedObj["bill_addr_1"] = street;
      modifiedObj["bill_addr_2"] = street2;
      modifiedObj["bill_city"] = city;
      modifiedObj["bill_taxid"] = taxid;
      modifiedObj["bill_taxid_type"] = taxonomyType;
      modifiedObj["bill_zip"] = zip;
      modifiedObj["bill_id"] = bill_id;
      modifiedObj["facility_name"] = name;
      modifiedObj["facility_npi"] = npi;
      modifiedObj["facility_addr_1"] = street;
      modifiedObj["facility_addr_2"] = street2;
      modifiedObj["facility_city"] = city;
      modifiedObj["facility_state"] = state;
      modifiedObj["facility_zip"] = zip;

      // check if claim is for KEI
      const isKEIClient = modifiedObj?.locationID === "eaac4977-fdd2-45dd-86ee-74b5125d3679";

      // if second npi is used then clear all providers and set this
      if (npi === "1043366164" && !isKEIClient) {
        modifiedObj["prov_npi"] = "1295866341";
        modifiedObj["prov_name_f"] = "DAVID";
        modifiedObj["prov_name_l"] = "MCDONOUGH";
        modifiedObj["prov_name_m"] = "A";
        modifiedObj["prov_taxid"] = "954781848";
      }
    }
  }
  return modifiedObj;
};

export const findMaxValueObject = (data, property) => {
  if (!Array.isArray(data) || data.length === 0) {
    return undefined; // Handle empty array or invalid input
  }

  return data.reduce((maxObj, obj) => (obj[property] > maxObj[property] ? obj : maxObj), data[0]);
};

export const getTotalCharge = (proc) => {
  const charge = getFloatVal(proc?.charge);

  if (charge === 0) return 0;

  return charge * parseInt(proc?.units || 1);
};

const updateRecordsWithMod = (records) => {
  const codePairs = [
    ["84100", "80069"],
    ["81009", "80307"],
    ["87625", "87624"],
    ["87186", "87184"],
    ["87481", "87801"],
    ["87661", "87801"],
    ["85027", "85025"],
    ["85014", "85027"],
    ["85018", "85027"],
    ["85048", "85027"],
    ["85049", "85027"],
    ["81003", "80307"],
    ["82947", "80053"],
    ["83721", "80061"],
    ["87340", "87147"],
    ["87389", "87147"],
    ["87491", "87801"],
    ["87591", "87801"],
    ["87798", "87801"],
  ];

  codePairs.forEach((pair) => {
    const firstCodeExistsIndex = records.findIndex((record) => record.proc_code === pair[0]);
    const secondCodeExists = records.some((record) => record.proc_code === pair[1]);

    if (firstCodeExistsIndex !== -1 && secondCodeExists) {
      const targetRecord = records[firstCodeExistsIndex];

      const existingModKeys = Object.keys(targetRecord).filter(
        (key) => key.startsWith("mod") && targetRecord[key] === "59"
      );
      if (existingModKeys.length === 0) {
        let i = 1;
        let modKey = "mod1";
        while (targetRecord.hasOwnProperty(modKey) && i < 5) {
          const modNum = i + 1;
          modKey = `mod${modNum}`;
          i++;
        }
        if (i < 5) {
          targetRecord[modKey] = "59";
        }
      }
    }
  });

  return records;
};

export const formatEmployeeClaim = (claim, setting, isMulti, isSecondary, era) => {
  const data = { ...claim, ...(claim.provider || {}) };

  const primaryIns = findMaxValueObject(data.toData, "status_code");

  const keysToDelete = [
    "mod1_2",
    "mod2_2",
    "mod3_2",
    "mod4_2",
    "proc_code_2",
    "thru_date_2",
    "units_2",
    "place_of_service_2",
    "proc_code_2",
    "remote_chgid_2",
  ];
  if (data) {
    keysToDelete.forEach((key) => {
      if (data.hasOwnProperty(key)) {
        delete data[key];
      }
    });
  }
  let procArray = [];
  let totalCharge = 0;
  if (data.proc_array.length > 0) {
    for (let i = 0; i < data.proc_array.length; i++) {
      const proc = data.proc_array[i];
      let pcode = proc.proc_code;
      let eraCPT = null;
      let pos = proc.place_of_service;

      if (isSecondary && era) {
        eraCPT = era.charge.find((f) => f.proc_code === proc.proc_code);
      }

      // TODO:: modify the logic of 36415
      const travelingCPT = ["P9604", "P9603"];

      if (MEDICARE_ID.includes(data.payerid) && travelingCPT.includes(pcode)) {
        pos = "12";
      }

      const obj = {
        diag_ref_1: proc.diag_ref_1,
        diag_ref_2: proc.diag_ref_2,
        diag_ref_3: proc.diag_ref_3,
        diag_ref_4: proc.diag_ref_4,
      };

      const ttlCharge = getTotalCharge(proc);
      totalCharge = totalCharge + ttlCharge;

      const index = i + 1;
      Object.assign(data, {
        [`diag_ref_${index}`]: Object.values(obj)
          .filter((f) => f)
          .join(","),
        [`from_date_${index}`]: proc.from_date,
        [`thru_date_${index}`]: proc.from_date,
        [`charge_${index}`]: ttlCharge,
        [`units_${index}`]: proc.units,
        [`proc_code_${index}`]: pcode,
        [`place_of_service_${index}`]: pos,
        [`remote_chgid_${index}`]: `${data.remote_claimid}-${index}`,
        [`mod1_${index}`]: proc.mod1,
        [`mod2_${index}`]: proc.mod2,
        [`mod3_${index}`]: proc.mod3,
        [`mod4_${index}`]: proc.mod4,
        ...(isSecondary && {
          [`primary_paid_amount_${index}`]: proc.paid,
          [`primary_paid_date_${index}`]: data.checkDate,
        }),
      });

      if (isSecondary && eraCPT && eraCPT.adjustment && eraCPT.adjustment.length > 0) {
        eraCPT?.adjustment?.forEach((item, i) => {
          const childIndex = i + 1;
          data[`adj_amt_${childIndex}_${index}`] = item.amount;
          data[`adj_code_${childIndex}_${index}`] = item.group + item.code; // or any other value you want to assign
        });
      }

      procArray.push({ ...proc, remote_chgid: `${data.remote_claimid}-${index}` });
    }
  }

  // add the Modifier into proc_array
  const modifiedProcArray = updateRecordsWithMod(procArray);
  const [countryCode, phoneNo] = getPhoneNo(data.bill_phone);

  Object.assign(data, {
    ...(totalCharge > 0 && { total_charge: totalCharge, proc_array: modifiedProcArray }),
    proc_array: modifiedProcArray,
    bill_phone: phoneNo,
    accept_assign: "Y",
    pat_country: "",
    pat_rel: "18",
    payer_icn: claim.status === CLAIM_SUBMIT_STATUS.denied && !MEDICARE_ID.includes(claim.payerid) ? claim.claimNo : "",
    assigned_comment: claim.assign_details?.message.replace(/'/g, "") || "",
    ...(isSecondary && {
      other_ins_name_l: data.pat_name_l,
      other_ins_name_f: data.pat_name_f,
      other_ins_name_m: data.pat_name_m,
      other_ins_dob: data.pat_dob,
      other_ins_sex: data.pat_sex,
      other_ins_payment_date: data.checkDate,
      other_payerid: primaryIns?.payerid,
      payer_order: "secondary",
      primary_allowed_amount: era?.total_allowed,
      other_payer_name: primaryIns?.payer_name,
      other_ins_number: primaryIns?.ins_number,
    }),
  });

  if (
    isSecondary &&
    data.payerid !== primaryIns?.payerid &&
    data.status === CLAIM_SUBMIT_STATUS.submitted &&
    !CUSTOM_INS.includes(data.payerid)
  ) {
    Object.assign(data, {
      toData: [
        ...(data.toData || []),
        {
          payer_name: data.payer_name,
          payerid: data.payerid,
          ins_number: data.ins_number,
          submissionDate: moment().toISOString(),
          status_code: (primaryIns?.status_code || 0) + 1,
        },
      ],
    });
  }

  // set the billing information according to the NPI set at insurnace level
  addCompanyInusranceNPI(data, setting);

  return data;
};

export const isJSONString = (string) => {
  if (!string || !string.trim()) return false;
  try {
    JSON.parse(string);
    return true;
  } catch (error) {
    return false;
  }
};

export const getDiagonosisCode = (str) => {
  return isJSONString(str) ? JSON.parse(str).code : str || "";
};

export const getDiagonosisInternalCodeValue = (str) => {
  return isJSONString(str) ? JSON.parse(str).internalCode || JSON.parse(str).code : str || "";
};

export const removeKeysFromObject = (obj, keysToRemove) => {
  if (!obj || typeof obj !== "object" || !Array.isArray(keysToRemove)) {
    return obj;
  }

  keysToRemove.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      delete obj[key];
    }
  });

  return obj;
};

export const setProceduresFromDate = (claimToBeEdited, date) => {
  const claim = { ...claimToBeEdited };
  const proc_arr_to_save = claim.proc_array || [];
  for (let i = 0; i < proc_arr_to_save.length; i++) {
    const obj = { ...proc_arr_to_save[i] };
    obj.from_date = date;
    proc_arr_to_save.splice(i, 1, obj);
  }
  Object.assign(claimToBeEdited, {
    proc_array: proc_arr_to_save,
    from_date_1: date,
  });
  return claimToBeEdited;
};

export const formatViewUpdateClaimList = (arr) => {
  return arr.map((obj) => {
    if (obj.eventType === "ClaimUpdated" && obj.changeObject) {
      let changeObject = {};
      Object.entries(obj.changeObject).forEach(([item, value]) => {
        let record = {};
        Object.entries(value).forEach(([m, v]) => {
          if (v?.M) {
            record = { ...record, ...(v?.M || {}) };
          }
          if (v?.L) {
            // for (const proc of v.L) {
            //   let procObj = {};
            //   if (proc.M) {
            //     Object.entries(procObj.M).forEach(([p, val], index) => {
            //       procObj[`${p}_${index + 1}`] = val;
            //     });
            //   }
            // }
            record = { ...record, ...(v.L[0]?.M || {}) };
          }
          record[m] = v;
        });
        changeObject[item] = record;
      });
      return { ...obj, changeObject };
    } else {
      return obj;
    }
  });
};

export const convertToDropDownOption = (arr, isCallFromClaimPage) => {
  let items = [...arr];
  if (items.indexOf("Date of Birth") === -1) items.push("Date of Birth");
  if (isCallFromClaimPage) {
    const keysToDelete = ["Processed By Secondary Ins", "Resubmitted Claims", "Crossover Claims", "Blank"];
    return items
      .filter((item) => !keysToDelete.includes(item))
      .map((v) => {
        return { value: v, label: v };
      });
  }
  return items
    .filter((item) => item !== "Black")
    .map((v) => {
      return { value: v.replace(/ /g, "_"), label: v };
    });
};

export const parseErrorMessageString = (input) => {
  if (!input) return [];
  const errorMessages = input.split("|");
  let parsedData = [];

  errorMessages.forEach((errorMessage) => {
    const [code, _, field, message] = errorMessage.split(":");
    const obj = { code, field, message };
    if (errorMessage.includes("(resolve)")) {
      Object.assign(obj, { resolve: "(resolve)" });
    }
    parsedData.push(obj);
  });

  if (parsedData.length === 1 && !parsedData[0].field && !parsedData[0].messages) {
    const errorMessages = input.split(",");
    const nestedPushed = [];
    errorMessages.forEach((errorMessage) => {
      const [code, _, field, message] = errorMessage.split(":");
      const obj = { code, field, message };
      if (errorMessage.includes("(resolve)")) {
        Object.assign(obj, { resolve: "(resolve)" });
      }
      nestedPushed.push(obj);
    });
    parsedData = nestedPushed;
  }

  return parsedData;
};

export const createErrorMessageString = (input) => {
  if (!input || !Array.isArray(input) || input.length === 0) return "";

  const errorMessages = input.map((error) => {
    if (!error) return "";
    const { code, field, message, resolve } = error;
    return `${code ? `${code}:` : ""}${field ? `${field}:` : ""}${message || ""}${resolve || ""}`;
  });

  return errorMessages.join("|");
};

export const getIntVal = (val) => {
  if (!val) return 0;
  try {
    return parseInt(val);
  } catch (err) {
    return 0;
  }
};

export const getFloatVal = (val) => {
  if (!val) return 0;
  try {
    return +parseFloat(val).toFixed(2);
  } catch (err) {
    return 0;
  }
};

// export const parseStringIntoFloat = (str) => {
//   if (!str) return "0.00";
//   if (!str?.trim()) return;
//   return str.replace(/[^0-9.]/g, "");
// };

export const parseStringIntoFloat = (str, format = false) => {
  if (str === undefined || str === null) return "";
  let result = str.replace(/[^0-9.]/g, "");
  let num = parseFloat(result);
  if (isNaN(num)) {
    return "";
  }

  return format ? num.toFixed(2) : result;
};

export const isMatchString = (originalFirstName, originalLastName, firstName, lastName) => {
  const similarityThreshold = 0.9; // Set the similarity threshold to 90%

  const firstNameSimilarity = calculateSimilarity(originalFirstName, firstName);
  const lastNameSimilarity = calculateSimilarity(originalLastName, lastName);

  return firstNameSimilarity >= similarityThreshold && lastNameSimilarity >= similarityThreshold;
};

const calculateSimilarity = (originalValue, value) => {
  const minLength = Math.min(originalValue.length, value.length);
  const maxLength = Math.max(originalValue.length, value.length);

  let matchingChars = 0;
  for (let i = 0; i < minLength; i++) {
    if (originalValue[i].toLowerCase() === value[i].toLowerCase()) {
      matchingChars++;
    }
  }

  const similarity = matchingChars / maxLength;
  return similarity;
};

export const trimObject = (obj) => {
  const trimmedObj = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const trimmedKey = key.trim();
      const value = obj[key];
      let trimmedValue;

      if (typeof value === "string") {
        trimmedValue = value.trim();
      } else if (typeof value === "object") {
        trimmedValue = trimObject(value);
      } else {
        trimmedValue = value;
      }

      trimmedObj[trimmedKey] = trimmedValue;
    }
  }

  return trimmedObj;
};

export const generateHL7Message = (claim) => {
  const mshSegment = `MSH|^~\&|${claim.prov_name}|SendingFacility||ReceivingFacility|${moment().format(
    "YYYYMMDDHHMM"
  )}||ORM^O01|Q2500661889|T|2.3`;
  const pidSegment = `PID|1||${claim.remote_claimid}||${claim.pat_name_l}^${claim.pat_name_f}^${
    claim.pat_name_m || ""
  }||${moment(claim.pat_dob).format("YYYYMMDD")}|${claim.pat_sex}|||${claim.pat_addr_1 || ""}^^${
    claim.pat_city || ""
  }^${claim.pat_state || ""}^${claim.pat_zip || ""}||${claim.pat_phone || ""}^CP^${claim.pat_email || ""}||||||`;
  const pvSegment = `PV1|1||SendingFacility|||||||||`;
  const in1Segment = `IN1|1|||${claim.payer_name || ""}|${claim.ins_addr_1 || ""}^^${claim.ins_city || ""}^${
    claim.ins_state || ""
  }^${claim.ins_zip || ""}|||||||Plan Effective Date|Plan Expiration Date|||${claim.ins_name_l}^${
    claim.ins_name_f
  }|Self|${moment(claim.ins_dob).format("YYYYMMDD")}|${claim.pat_addr_1 || ""}^^${claim.pat_city || ""}^${
    claim.pat_state || ""
  }^${claim.pat_zip || ""}|||||||||||||||||InsurancePolicy#|||||||||||T|||`;

  const gt1segement = `GT1|1||${claim.pat_name_l}^${claim.pat_name_f}||${claim.pat_addr_1 || ""}^^${
    claim.pat_city || ""
  }^${claim.pat_state || ""}^${claim.pat_zip || ""}|||${moment(claim.pat_dob).format("YYYYMMDD")}|${
    claim.pat_sex
  }||Self|`;

  const dg1Segment = `DG1|1|ICD-10|${claim.diag_1}`;

  const hl7Message = [mshSegment, pidSegment, pvSegment, in1Segment, gt1segement, dg1Segment].join("\r");

  return hl7Message;
};

export const calculateStringSimilarity = (str1, str2) => {
  const matrix = [];

  if (!str1 && !str2) return 0;

  for (let i = 0; i <= str1.length; i++) {
    matrix[i] = [i];
    for (let j = 1; j <= str2.length; j++) {
      if (i === 0) {
        matrix[i][j] = j;
      } else {
        const cost = str1[i - 1] !== str2[j - 1] ? 1 : 0;
        matrix[i][j] = Math.min(matrix[i - 1][j] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j - 1] + cost);
      }
    }
  }

  const maxLen = Math.max(str1.length, str2.length);
  const distance = matrix[str1.length][str2.length];
  return 1 - distance / maxLen;
};

export const formatLabelID = (id) => {
  if (!id) return "";
  const splitId = id.split("-");
  return splitId[splitId.length - 1];
};

export const isSimilarPayer = (provider, payerName) => {
  const similarityThreshold = 0.8;
  const { label, payer_alt_names } = provider;

  if (label.toLowerCase() === payerName.toLowerCase()) {
    return true;
  }

  if (calculateStringSimilarity(label.toLowerCase(), payerName.toLowerCase()) > similarityThreshold) {
    return true;
  }

  if (payer_alt_names) {
    const similarAltNames = payer_alt_names.some(
      (altName) =>
        altName.alt_payer_name &&
        calculateStringSimilarity(altName.alt_payer_name.toLowerCase(), payerName.toLowerCase()) > similarityThreshold
    );

    if (similarAltNames) {
      return true;
    }
  }

  return false;
};

export const parseInsDetails = (insuranceDetails, user) => {
  if (Array.isArray(insuranceDetails) && insuranceDetails.length > 0) {
    return insuranceDetails.map((m, i) => {
      if (i === 0) {
        return { ...m, isPrimary: true };
      }
      return { ...m, isPrimary: false };
    });
  } else {
    if (!user?.insuranceCompany) return [];
    const primary = {
      id: "prefix_" + Math.random().toString(36).slice(2, 9),
      insuranceCompany: user.insuranceCompany,
      insuranceCompanyCode: user.insuranceCompanyCode,
      insuranceType: user.insuranceType,
      copay: user.copay,
      deductible: user.deductible,
      insuranceGroupId: user.insuranceGroupId,
      isMedicare: user.isMedicare,
      medicalNo: user.medicalNo,
      medicareNo: user.medicareNo,
      isPrimary: true,
      isActive: true,
    };
    const insDetails = [primary];
    if (insuranceDetails && insuranceDetails.insuranceCompany) {
      insDetails.push({ ...insuranceDetails, isActive: true, id: "prefix_" + Math.random().toString(36).slice(2, 9) });
    }
    return insDetails;
  }
};

export const updateInsurancefromClaim = (insuranceDetails, claim) => {
  const { payerid, payer_name, ins_number } = claim;

  const newInsuranceObject = {
    id: "prefix_" + Math.random().toString(36).slice(2, 9),
    insuranceCompany: payerid,
    insuranceCompanyCode: payer_name,
    isMedicare: payer_name?.includes("Medicare"),
    medicalNo: ins_number,
    medicareNo: payer_name?.includes("Medicare") ? ins_number : "",
    isPrimary: true,
    isActive: true,
  };

  if (Array.isArray(insuranceDetails) && insuranceDetails.length > 0) {
    const existingInsurance = insuranceDetails.find((insurance) => insurance.insuranceCompany === payerid);
    if (!existingInsurance) {
      return [...insuranceDetails, { ...newInsuranceObject, isPrimary: false }];
    }
    return insuranceDetails;
  } else {
    return [newInsuranceObject];
  }
};
export const showElementsByClassName = (className) => {
  const elements = document.getElementsByClassName(className);
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.display = "";
  }
};

export const hideElementsByClassName = (className) => {
  const elements = document.getElementsByClassName(className);
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.display = "none";
  }
};

export const getInsuranceNumber = (inputString) => {
  if (/^[a-zA-Z]/.test(inputString)) {
    const parts = inputString.split(/(?<=[a-zA-Z])(?=\d)/);
    return parts[1];
  }
  return inputString;
};

const isPrefixExists = (inputString) => {
  if (inputString.length < 3) return false;
  const firstThreeCharacters = inputString.slice(0, 3);
  return /^[a-zA-Z]+$/.test(firstThreeCharacters);
};

export const getInsuranceNumberWithPrefix = (payerid, insNo) => {
  const payerids = ["47198", "94036", "57115"];

  if (!payerids.includes(payerid)) {
    return insNo;
  }

  if (isPrefixExists(insNo)) return insNo;

  const insNoWithOutPrefix = getInsuranceNumber(insNo);

  return `XDL${insNoWithOutPrefix}`;
};

export const isAmountGreaterThanCharge = (total, charge) => {
  return parseFloat(total || 0) >= parseFloat(charge || 0);
};

export const isAmountEqualToCharge = (total, charge) => {
  return parseFloat(total || 0) === parseFloat(charge || 0);
};

export const generateInvoiceOutStanding = (claim) => {
  if (!claim) return 0;
  const { proc_array } = claim;
  if (!proc_array) return 0;

  const procArray = proc_array.map((p) => {
    const { charge, paid, write_off, mw_off } = p;
    const open =
      parseFloat(charge || 0) -
      parseFloat(paid || 0) -
      parseFloat(write_off || 0) -
      (mw_off ? parseFloat(mw_off || 0) - parseFloat(write_off || 0) : 0);
    return { ...p, open };
  });
  return procArray.reduce((res, obj) => parseFloat(res || 0) + obj.open, 0);
};

export const calculateOpenAmount = (claim) => {
  const { proc_array } = claim;
  const procArray = proc_array.map((p) => {
    const { charge, paid, write_off, mw_off } = p;
    const open =
      parseFloat(charge || 0) -
      parseFloat(paid || 0) -
      parseFloat(write_off || 0) -
      (mw_off ? parseFloat(mw_off || 0) - parseFloat(write_off || 0) : 0);
    return { ...p, open };
  });
  return { ...claim, proc_array: procArray };
};

export const generateOpenAmountOfCPT = (claims) => {
  if (!claims) return [];

  return claims.map((m) => calculateOpenAmount(m));
};

export const parseInvoiceData = (claimData, loginUser, clients, setting) => {
  let claimClients = customKeyBy(clients, "id");

  let paramDataArr = [];

  let uniqueDataMap = new Map();

  for (const data of claimData) {
    let errorMessage = "";
    let invoiceAlreadySent = false;
    const { invoiceID, invoiceNo, invoiceSendDate } = data?.toData?.find((f) => f.invoiceNo) || {};

    if (invoiceSendDate && moment(invoiceSendDate).isSame(moment(), "day")) {
      errorMessage = "Invoice Already Sent.";
      invoiceAlreadySent = true;
    }

    let uniqueKey;

    let sendTo = data.payerid === "00001" ? "Patient" : "Client";

    if (sendTo === "Patient") {
      uniqueKey = data.employeeID;
    } else if (sendTo === "Client") {
      uniqueKey = data.clientID;
    }

    let toData = {};

    let barCode = data.remote_claimid.replace("CL-", "");

    if (data.payerid === "00002") {
      const client = claimClients[data.locationID];
      const { firstName: clientFistName, lastName: clientLastName } = splitClientName(client?.name);
      toData = {
        id: client.id,
        isActive: true,
        payerid: data.payerid,
        payer_name: data.payer_name,
        firstName: clientFistName || "",
        lastName: clientLastName || "",
        street: client?.street || "",
        state: client?.state ? `${client.state},` : "",
        zip: client?.zip || "",
        city: client?.city || "",
        country: client?.country || "",
        email: client?.email,
        sendToType: client?.setting?.billType,
        sendTo: sendTo,
        barCode,
        pcn: client.practiceID,
      };
    } else {
      toData = {
        id: data.employeeID,
        isActive: true,
        payerid: data.payerid,
        payer_name: data.payer_name,
        firstName: data.pat_name_f,
        lastName: data.pat_name_l,
        street: data.pat_addr_1,
        street2: data.pat_addr_2,
        city: data.pat_city,
        state: data.pat_state,
        faxSubject: "",
        country: data.pat_country,
        zip: data.pat_zip,
        pcn: data.pcn,
        email: data.pat_email,
        sendTo: sendTo,
        barCode,
        outStandingBalance: 0,
      };
    }

    let paramData = {
      id: invoiceID,
      invoiceSendDate: moment().toISOString(),
      invoiceDate: moment().toISOString(),
      dueDate: moment().add(10, "days").toISOString(),
      sendToType: data.sendToType,
      sendTo: sendTo,
      employeeID: data.employeeID,
      details: [],
      invoiceAmount: generateInvoiceOutStanding(data),
      minAmount: data.minimumAmount ? Number(parseFloat(data.minimumAmount).toFixed(2)) : 0,
      claimID: data.id,
      local_id: data.id,
      clientID: userCompanyID.get() || CONFIG.generalClient,
      companyID: data.locationID,
      subAgentID: data.subAgentID,
      reSubmissionDate: data.reSubmissionDate,
      status: "pending",
      claimStatus: data.status,
      errorMessage: errorMessage,
      invoiceAlreadySent,
      udpatedBy: loginUser?.sub,
      updatedByName: loginUser?.name,
      message: INVOICE_DEFAULT_MESSAGE,
      invoiceNo: invoiceNo ?? "00000",
      payer_name: data.payer_name,
      firstName: data.pat_name_f,
      lastName: data.pat_name_l,
      toData: toData,
      fromData: {
        id: setting.id,
        companyName: setting.name,
        street: setting.street,
        street2: setting.street2,
        city: setting.city,
        state: setting.state,
        zip: setting.zip,
        phone: setting.phoneNumber,
      },
    };

    if (!uniqueDataMap.has(uniqueKey)) {
      uniqueDataMap.set(uniqueKey, paramData);
      paramDataArr.push(paramData);
    }
  }
  return paramDataArr;
};

export const createBulkInvoiceData = (invoicesData) => {
  let invoiceData = [];
  let resendInvoiceData = [];
  for (const singleInvoiceData of invoicesData) {
    if (singleInvoiceData.id && !singleInvoiceData.invoiceAlreadySent) {
      const invoiceObj = {
        invoiceID: singleInvoiceData.id,
        local_id: singleInvoiceData.id,
        message: singleInvoiceData.message,
      };
      resendInvoiceData.push(invoiceObj);
    } else if (!singleInvoiceData.id) {
      invoiceData.push(singleInvoiceData);
    }
  }

  return { invoiceData, resendInvoiceData };
};

export function replacePlaceholders(str, values) {
  return str.replace(/\{\{(.*?)\}\}/g, (match, key) => {
    return values[key] || match;
  });
}

export function createUniqueAppealFileName() {
  const now = new Date();
  const formattedDate = now
    .toISOString()
    .replace(/[^0-9]/g, "")
    .substring(0, 14);
  const staticWord = "appeal_document";
  return `${formattedDate}_${staticWord}`;
}
export const getEmployeeIndexValue = (employee) => {
  let employeeFND = `${employee?.firstName ?? ""}${employee.middleName ?? ""}${employee?.lastName ?? ""}`;
  return employeeFND.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
};

export const downloadFileFromS3 = async (filePath, fileName, bucket) => {
  let alink = null;
  let fileURL = null;

  try {
    const data = await Storage.get(filePath, { bucket: bucket });
    const response = await fetch(data);

    if (!response.ok) {
      return false;
    }

    const blob = await response.blob();

    if (blob.size === 0) {
      return false;
    }

    fileURL = window.URL.createObjectURL(blob);
    alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = fileName;
    document.body.appendChild(alink);
    alink.click();

    return true;
  } catch (error) {
    console.error("Error downloading requisition zip:", error);
    return false;
  } finally {
    if (alink) {
      document.body.removeChild(alink);
      window.URL.revokeObjectURL(fileURL);
    }
  }
};

export const createInvoicePayload = (claimData, isEditInvoice, viewType) => {
  const payload = { clients: [], employees: [] };

  const normalizedClaimData = Array.isArray(claimData) ? claimData : [claimData];
  normalizedClaimData.forEach((item) => {
    console.log("Item", item);
    if (item.payer_name === "Bill Patient") {
      const payloadObj = {
        id: item.employeeID,
        discount: item.discount || 0,
        discountType: item?.discountType || "amount",
        minAmount: item.minAmount || 0,
        message: item.message,
        details: isEditInvoice ? item.details : [],
        viewType: "detail",
      };
      payload.employees.push(payloadObj);
    } else if (item.payer_name === "Bill Client") {
      const payloadObj = {
        id: item.companyID,
        discount: item.discount || 0,
        discountType: item?.discountType || "amount",
        minAmount: item.minAmount || 0,
        message: item.message,
        details: isEditInvoice ? item.details : [],
        viewType,
      };
      payload.clients.push(payloadObj);
    }
  });

  return {
    clients: Array.from(payload.clients),
    employees: Array.from(payload.employees),
  };
};

export const splitClientName = (inputString) => {
  const words = inputString.split(" ");
  let firstName, lastName;

  if (words.length === 1) {
    firstName = words[0];
    lastName = "";
  } else if (words.length === 2) {
    firstName = words[0];
    lastName = words[1];
  } else {
    firstName = words[0];
    lastName = words.slice(1).join(" ");
  }

  return { firstName, lastName };
};

export const isObjectCompletelyEmpty = (obj) => {
  if (Object.keys(obj).length === 0) {
    return true;
  }

  for (const key in obj) {
    const value = obj[key];
    if (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !(Array.isArray(value) && value.length === 0) &&
      !(typeof value === "object" && Object.keys(value).length === 0)
    ) {
      return false;
    }
  }
  return true;
};

export const validateNumber = (number) => {
  if (number === "") {
    return true;
  }
  const regex = /^0$|^0\.\d*$|^[1-9]\d*(\.\d*)?$/;
  return regex.test(number.toString());
};

export const vhToPixels = (vh) => {
  return (vh * window.innerHeight) / 100;
};

export const nestedFilter = (targetArray, filters) => {
  if (Object.keys(filters).length === 0) return targetArray;
  const filterKeys = Object.keys(filters);
  const models = targetArray.filter((obj) => {
    return filterKeys.every((key) => {
      if (!filters[key].length) {
        return true;
      }

      return obj[key] && obj[key].toLowerCase().includes(filters[key]);
    });
  });
  return models;
};

const getUserDataFromLocalStorage = (suffix) => {
  const keys = Object.keys(localStorage);
  const userDataKey = keys.find((key) => key.endsWith(suffix));
  if (userDataKey) {
    const userData = localStorage.getItem(userDataKey);
    return JSON.parse(userData); // Parse once when the key is found
  }
  return null;
};

const processUserData = (attributes) => {
  const attrMap = attributes.reduce((map, attr) => {
    map[attr.Name] = attr.Value;
    return map;
  }, {});

  const id = attrMap["sub"];
  const firstName = attrMap["custom:firstName"];
  const lastName = attrMap["custom:lastName"];
  const name = `${firstName} ${lastName}`;

  return { requestedBy: id, requestedByName: name };
};

export const getLoginUserInfoFromLocal = () => {
  const userData = getUserDataFromLocalStorage(".userData");
  if (userData && userData.UserAttributes) {
    return processUserData(userData.UserAttributes);
  }
  return null;
};

export const getERAUniqueIds = (existingERA) => {
  const result = new Set();

  for (const era of existingERA) {
    const eraIds = era.eraIds || "";
    const lastKey = eraIds.split(",").pop().trim();

    const uniqueId = lastKey ? `${era.pcn}#${lastKey}` : era.pcn;

    result.add(uniqueId);
  }

  return Array.from(result).sort();
};
export const mergePersonlizationColumns = (defaultColumns, clientColumns) => {
  const allColumns = [...defaultColumns, ...clientColumns];
  const uniqueColumnsMap = new Map();

  allColumns.forEach((item) => {
    uniqueColumnsMap.set(item.itemKey, item);
  });

  return Array.from(uniqueColumnsMap.values());
};
export function mergeArraysById(arrayToMap, arrayToSearch) {
  return arrayToMap.map((obj) => {
    const matchingObject = arrayToSearch.find((e) => e.id === obj.id);
    return { ...matchingObject, ...obj };
  });
}

export const parseJSONObject = (obj) => {
  if (!obj) return {};
  return typeof obj === "string" ? JSON.parse(obj) : obj;
};

export const parseStringToJSON = (obj) => {
  if (!obj) return obj;
  return typeof obj === "string" ? JSON.parse(obj) : obj;
};

const mergeColumns = (defaultColumns, clientColumnMap) => {
  const mergedColumns = defaultColumns.map((item) => {
    return clientColumnMap.get(item.itemKey) || item;
  });

  const additionalColumns = clientColumns.filter(
    (clientColumn) => !defaultColumns.some((defaultColumn) => defaultColumn.itemKey === clientColumn.itemKey)
  );

  return [...mergedColumns, ...additionalColumns];
};

export const getTimeZoneAbr = () => {
  return moment().tz(moment.tz.guess()).format("z");
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const parseUserName = (item) => {
  const name = item?.userName || "System Generated";
  if (name === "Auto" || name === "System") return "System Generated";
  return name;
};

export const mergeUserPermissions = (customPermissions) => {
  const updatedPermissions = { ...DEFAULT_USRERS_PERMISSIONS };

  for (const key in customPermissions) {
    if (customPermissions.hasOwnProperty(key)) {
      if (typeof customPermissions[key] === "object" && customPermissions[key] !== null) {
        updatedPermissions[key] = {
          ...DEFAULT_USRERS_PERMISSIONS[key],
          ...customPermissions[key],
        };
      } else {
        updatedPermissions[key] = customPermissions[key];
      }
    }
  }

  return updatedPermissions;
};

export const formatSite = (obj) => {
  if (!obj) return obj;
  return {
    ...obj,
    setting: obj.setting ? JSON.parse(obj.setting) : null,
  };
};

export const formatProvider = (obj) => {
  if (!obj) return obj;
  return {
    ...obj,
    setting: obj.setting ? JSON.parse(obj.setting) : null,
  };
};

let url = window.location.hostname;

export const getUserName = async (phone, companies, handleError) => {
  let companiesList = companies;
  if (!companiesList || companiesList.length === 0) {
    const data = await API.fetchAllCompanies();
    companiesList = data.data;
  }
  const company = companiesList?.find((f) => url.includes(f.ltcLink)) || {
    code: process.env.REACT_APP_COMPANY_CODE || "004",
  };
  if (company.clientID && !company.isActive) {
    handleError();
    return;
  }

  if (
    CONFIG.isLabType &&
    (company.clientID || (url === "localhost" && CONFIG.generalClient)) &&
    ADMIN_USERS.includes(phone)
  ) {
    userCompanyID.save(company.clientID || CONFIG.generalClient);
  }

  const username = (url.includes("altalab")) && ADMIN_USERS.includes(phone) ? phone : `${phone}_${company.code}`;
  return username;
};

export const updateContactPreferences = (employee) => {
  const updatedEmployee = { ...employee };

  if (updatedEmployee.phoneNumber && !updatedEmployee.email) {
    updatedEmployee.sendSMS = true;
    updatedEmployee.sendEmail = false;
  } else if (!updatedEmployee.phoneNumber && updatedEmployee.email) {
    updatedEmployee.sendSMS = false;
    updatedEmployee.sendEmail = true;
  } else {
    updatedEmployee.sendSMS = false;
    updatedEmployee.sendEmail = false;
  }

  return updatedEmployee;
};

export const eligibilityMessage = (item, row) => {
  if (row && (CUSTOM_INS.includes(row?.insuranceCompanyCode) || CUSTOM_INS.includes(row?.insuranceCompany))) {
    return "Eligibile";
  }

  if (!item) return "Not Eligibile";
  let message = item?.status || item?.message;

  if (message && message.toLowerCase() === "ineligibile") message = "Not Eligibile";
  return message || "Not Eligibile";
};

export const getStartAndEndDateFromEligibility = (dateString) => {
  if (!dateString) return { startDate: null, endDate: null };
  const [startDateString, endDateString] = dateString.split("-");

  const startDate = moment(startDateString, "YYYYMMDD").format("YYYY-MM-DD");
  const endDate =
    !endDateString || endDateString === "99991231"
      ? moment().format("YYYY-MM-DD")
      : moment(endDateString, "YYYYMMDD").format("YYYY-MM-DD");

  return { startDate, endDate };
};

export const getDenialCode = () => {
  return ERA_DENAIEL_REASONS.map(({ Code, Reason }) => (
    <option key={Code} value={Code}>
      {Code}
    </option>
  ));
};

export const nameToTitleCase = (str) => {
  if (typeof str !== "string" || !str.trim()) {
    return "";
  }

  if (str.length <= 2) {
    return str.toUpperCase();
  }

  return str.replace(/(\b\w{1,3}\b|\b\w{4,}\b)/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};

export const isCptCodeObjInDb = ({ cptCodeObj, clientDbList }) => {
  const codeA = cptCodeObj.cptA.proc_code;
  const codeB = cptCodeObj.cptB.proc_code;

  for (let i = 0; i < clientDbList.length; i++) {
    const dbItem = clientDbList[i];
    const dbCodeA = dbItem.cptCodeObj.cptA.proc_code;
    const dbCodeB = dbItem.cptCodeObj.cptB.proc_code;

    const dbCodes = [dbCodeA, dbCodeB];

    if (dbCodes.includes(codeA) && dbCodes.includes(codeB)) {
      return true;
    }
  }
  return false;
};

export const isCptModifireObjInDb = ({ cptCodeObj, clientDbList }) => {
  const codeA = cptCodeObj.cptA.proc_code;
  const codeB = cptCodeObj.cptB.proc_code;

  for (let i = 0; i < clientDbList.length; i++) {
    const dbItem = clientDbList[i];
    const dbCodeA = dbItem.modifireCodeObj.cptA.proc_code;
    const dbCodeB = dbItem.modifireCodeObj.cptB.proc_code;

    const dbCodes = [dbCodeA, dbCodeB];

    if (dbCodes.includes(codeA) && dbCodes.includes(codeB)) {
      return true;
    }
  }
  return false;
};

export const isModifireObjInDb = ({ modifireCodeObj, clientDbList }) => {
  const modifire = modifireCodeObj.modifire;
  return clientDbList.some((item) => item.modifire === modifire);
};

export const fetchClientCptCodesList = async () => {
  const fetchedClient = await API.getClientById(userCompanyID.get());
  const cptCodes = fetchedClient?.mdSetting?.cptCodesList || [];

  const formatedCptCodes = cptCodes.map((record) => [
    record.cptCodeObj.cptA.proc_code,
    record.cptCodeObj.cptB.proc_code,
  ]);

  return formatedCptCodes;
};

export const addModifireInProcArray = async (mergedObj) => {
  const fetchedClient = await API.getClientById(userCompanyID.get());

  const modifireList = fetchedClient?.mdSetting?.cptModifiresList || [];

  const formatedModifireCodes = modifireList.map((record) => [
    record.modifireCodeObj?.cptA.proc_code,
    record.modifireCodeObj?.cptB.proc_code,
    record.modifireCodeObj?.modifire,
  ]);

  const procArrayObj = mergedObj.proc_array || [];

  for (let pair of formatedModifireCodes) {
    const [firstCode, secondCode, modifier] = pair;

    const firstIndex = procArrayObj.findIndex((obj) => obj.proc_code === firstCode);

    const secondExists = procArrayObj.some((obj) => obj.proc_code === secondCode);

    if (firstIndex !== -1 && secondExists) {
      let firstObj = procArrayObj[firstIndex];
      const modifierAssigned = procArrayObj.some((obj) => {
        return [obj.mod1, obj.mod2, obj.mod3, obj.mod4, obj.mod5, obj.mod6, obj.mod7, obj.mod8].includes(modifier);
      });

      if (!modifierAssigned) {
        for (let i = 1; i <= 8; i++) {
          const modKey = `mod${i}`;
          if (!firstObj[modKey]) {
            firstObj = { ...firstObj, [modKey]: modifier };
            break;
          }
        }
        procArrayObj[firstIndex] = firstObj;
      }
    }
  }

  const modifiedObj = { ...mergedObj, proc_array: procArrayObj };
  return modifiedObj;
};

export const addHospiceModifireInClaimProcArray = (claimObj) => {
  const procArray = claimObj.proc_array || [];

  if (!procArray.length) return claimObj;

  let gwAdded = procArray.some((obj) => [1, 2, 3, 4].some((i) => obj[`mod${i}`]?.includes(HOSPICE_MODFIRE.GW)));

  if (gwAdded) {
    return claimObj;
  }

  for (let obj of procArray) {
    for (let i = 1; i <= 4; i++) {
      const modKey = `mod${i}`;
      if (!obj[modKey]?.trim()) {
        obj[modKey] = HOSPICE_MODFIRE.GW;
        return { ...claimObj, proc_array: [...procArray] };
      }
    }
  }

  return { ...claimObj, proc_array: [...procArray] };
};

const isPlainObject = (item) => {
  return item !== null && typeof item === "object" && !Array.isArray(item);
};






// export const createChangeObj = ({ oldObj = {}, newObj = {} }, parentKey = "") => {
//   if (oldObj.isNew) return [];

//   let changes = [];

//   const allKeys = new Set([
//     ...Object.keys(oldObj || {}),
//     ...Object.keys(newObj || {}),
//   ]);

//   for (const key of allKeys) {
//     const oldVal = oldObj[key];
//     const newVal = newObj[key];

//     const currentLabel = parentKey ? `${parentKey}.${key}` : key;
//     if (isPlainObject(oldVal) && isPlainObject(newVal)) {
//       const nestedChanges = createChangeObj(
//         { oldObj: oldVal, newObj: newVal },
//         currentLabel
//       );
//       changes.push(...nestedChanges);
//     } 
//     else if (oldVal !== newVal) {
//       changes.push({
//         label: currentLabel,
//         before: oldVal === undefined ? "" : oldVal,
//         after: newVal === undefined ? "" : newVal,
//       });
//     }
//   }

//   return changes;
// };




const getCustomLabel = (key) => LABEL_MAP_USER_LOGS[key] || key;

export const createChangeObj = (
  { oldObj = {}, newObj = {} },
  parentKey = ""
) => {

  if (oldObj.isNew) return [];

  let changes = [];


  const allKeys = new Set([
    ...Object.keys(oldObj || {}),
    ...Object.keys(newObj || {}),
  ]);


  for (const key of allKeys) {
    const oldVal = oldObj[key];
    const newVal = newObj[key];


    const currentKey = parentKey ? `${parentKey}.${key}` : key;

    if (isPlainObject(oldVal) && isPlainObject(newVal)) {
      const nestedChanges = createChangeObj(
        { oldObj: oldVal, newObj: newVal },
        currentKey
      );
      changes.push(...nestedChanges);
    } else if (oldVal !== newVal) {

      const label = getCustomLabel(currentKey);

      changes.push({
        label, 
        before: oldVal === undefined ? "" : oldVal,
        after: newVal === undefined ? "" : newVal,
      });
    }
  }

  return changes;
};
