import { useEffect, useRef } from 'react';

export const useIdleTimerHook = ({ timeout = 3600, onIdle }) => {
  const timerRef = useRef(null);

  const updateLastActive = () => {
    localStorage.setItem('lastActiveTime', Date.now().toString());
  };


  const resetTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      onIdle();
    }, timeout * 1000);
  };

  useEffect(() => {
    const storedTimestamp = localStorage.getItem('lastActiveTime');
    if (storedTimestamp) {
      const lastActiveTime = parseInt(storedTimestamp, 10);
      const now = Date.now();
      const elapsedSeconds = (now - lastActiveTime) / 1000;

      if (elapsedSeconds > timeout) {
        onIdle();
        return;
      }
    } else {
      updateLastActive();
    }


    const activityEvents = [
      'mousemove',
      'keydown',
      'wheel',
      'touchstart',
      'click',
      'scroll',
    ];

    const handleActivity = () => {
      updateLastActive(); 
      resetTimer();  
    };

    activityEvents.forEach((event) =>
      window.addEventListener(event, handleActivity)
    );

    resetTimer();


    return () => {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [timeout, onIdle]);

  return null;
}
