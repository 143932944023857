import moment from "moment";
import React, { useState } from "react";
import { Table, Modal } from "react-bootstrap";

const LogsDetailView = (props) => {
  const { cssClass, changeObj, isNewUser } = props;

  const [detailsData, setDetailsData] = useState([]);

  const onlyShowDateLabels = ["Test Date", "Dob"];

  const [showDetailModal, setShowDetailModal] = useState(false);

  const TableRow = ({ rowItem, index }) => {
    const formatResult = (result, label) => {
      if (typeof result === "boolean") {
        return result ? "Yes" : "No";
      }

      if (typeof result === "string" && result.includes("-") && moment(result, moment.ISO_8601, true).isValid()) {
        if (onlyShowDateLabels.includes(label)) {
          return moment(result).format("MM-DD-YYYY");
        }
        return moment(result).format("MM-DD-YYYY hh:mm A");
      }

      return result || "";
    };

    return (
      <tr key={index}>
        <td className="popOver-centered border-0">{rowItem.label}</td>
        <td className="popOver-centered border-0">{formatResult(rowItem.before, rowItem.after)}</td>
        <td className="popOver-centered border-0">{formatResult(rowItem.after, rowItem.before)}</td>
      </tr>
    );
  };

  const handleShowDetailModal = () => {
    if (!isNewUser) {
      setDetailsData(changeObj);
      setShowDetailModal(true);
    }
  };
  const handleCloseDetailModal = () => {
    setDetailsData([]);
    setShowDetailModal(false);
  };

  return (
    <>
      <td
        className={cssClass}
        onClick={handleShowDetailModal}
        style={{
          cursor: "pointer",
          display: "flex",
          width: "100%",
        }}
      >
        {isNewUser ? "User Created" : "View Details"}
      </td>
      {showDetailModal && (
        <Modal
          show
          animation={true}
          size="lg"
          onHide={handleCloseDetailModal}
          centered
          scrollable
          className="searchList seperateModal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="my-0" id="contained-modal-title-vcenter">
              Detail Logs
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "0px" }}>
            <div className="form-group-wrapper d-block">
              <div className="table-responsive overFlow-y-hidden">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="popOver-centered border-0">Label</th>
                      <th className="popOver-centered border-0">Before</th>
                      <th className="popOver-centered border-0">After</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(detailsData) &&
                      detailsData.map((val, i) => {
                        return <TableRow key={i} index={i} rowItem={val} />;
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default LogsDetailView;
