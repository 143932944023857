import Loader from "components/Loader/Loader";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import API from "api";
import { formatOrderDate } from "utils";
import LogsDetailView from "./LogsDetailView";
import { MESSAGE_MODES } from "constant";
import { useDispatch } from "react-redux";
import { setMessage } from "store/features/general/generalAction";

const LogsModal = ({ handleClose, logsType, title }) => {
  const [logsData, setLogsData] = useState([]);


  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const getLogsData = async () => {
    setLoading(true);
    const logs = await API.getEmployeeLogs(logsType.id);

    const sorted = logs.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    setLogsData(sorted);
    setLoading(false);
  };

  useEffect(() => {
    getLogsData();
  }, []);

  const TableRow = ({ item, sr }) => {
    return (
      <>
        <tr>
          <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}></td>
          <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}>
            {logsData.length - sr}
          </td>
          <td style={{ width: "200px", minWidth: "200px", maxWidth: "200px" }}>{formatOrderDate(item.createdAt)}</td>
          <td
            className={`ellipsis `}
            style={{
              cursor: "pointer",
            }}
          >
            {item.eventType}
          </td>

          <td
            className={`ellipsis`}
            style={{
              cursor: "pointer",
            }}
          >
            <LogsDetailView changeObj={item.changeObj} isNewUser={item.eventData === "NewUser" ? true : false} />
          </td>

          <td className={`ellipsis`}>{item.userName}</td>

          <td
            onClick={async () => {
              setLoading(true);
              await API.deleteLogRecord(item.id);
              await getLogsData();
              dispatch(setMessage("delete Successfully", MESSAGE_MODES.success));
            }}
          >
            <i className="fas fa-trash cursor-pointer" />
          </td>
        </tr>
      </>
    );
  };

  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {title || "User Logs"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        {loading && <Loader />}
        <Table className="table-hover table-striped logsTable">
          <thead>
            <tr>
              <th className="tdWidth10" />
              <th
                className={`ellipsis border-0`}
                style={{
                  color: "grey",
                  fontWeight: "bold",
                }}
              >
                Sr#
              </th>
              <th
                className={`ellipsis border-0`}
                style={{
                  color: "grey",
                  fontWeight: "bold",
                }}
              >
                <div>
                  <span>Date</span>
                </div>
              </th>
              <th
                className={`ellipsis border-0`}
                style={{
                  color: "grey",
                  fontWeight: "bold",
                }}
              >
                <div>
                  <span>Event</span>
                </div>
              </th>

              <th
                className={`ellipsis border-0`}
                style={{
                  color: "grey",
                  fontWeight: "bold",
                }}
              >
                <div>
                  <span>Action</span>
                </div>
              </th>
              <th
                className={`ellipsis border-0`}
                style={{
                  color: "grey",
                  fontWeight: "bold",
                }}
              >
                <div>
                  <span>User</span>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {logsData &&
              logsData.map((o, index) => {
                return <TableRow key={index} item={o} sr={index} />;
              })}
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <Button className="modalButtons" variant="primary" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogsModal;
